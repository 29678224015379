import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoCloseOutline, IoRefreshOutline, IoWalletOutline } from "react-icons/io5";

import { FormatDate, FormatPrice } from "utils/Format";
import { DataGrid } from "components/grid";
import { Badge, ContentHeading, OptionMenu } from "components/ui";
import ModalBillingPayments from "./ModalBillingPayments";
import ModalBillingDeleteKid from "./ModalBillingDeleteKid";
import dayjs from "dayjs";
import ModalBillingRestoreKid from "./ModalBillingRestoreKid";
import { BillingStatus } from "utils/BillingStatus";

const today = dayjs();

function BillingList({ billings, handleLoadData }) {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null });

    const columns = [
        {
            field: "brand",
            headerName: t("Brand", { ns: "common" }),
            width: 170,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "institution",
            headerName: t("Institution", { ns: "common" }),
            width: 170,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "whoPay",
            headerName: t("Who pay", { ns: "common" }),
            width: 170,
            valueGetter: (value, row) => {
                if (row.type.value === "PARENT_PAY_BRAND") {
                    return row.whoPays.fullName;
                } else if (row.type.value === "INSTITUTION_PAY_BRAND") {
                    return row.institution.name;
                }

                return "---";
            },
        },
        {
            field: "phone",
            headerName: t("Phone", { ns: "common" }),
            width: 140,
            valueGetter: (value, row) => `${(row.whoPays.phone && row.whoPays.phone) || "---"}`,
        },
        {
            field: "kid",
            headerName: t("Kid", { ns: "common" }),
            width: 160,
            valueGetter: (value, row) => `${(value && value.fullName) || "---"}`,
        },
        {
            field: "activities",
            headerName: t("Activities", { ns: "common" }),
            flex: 1,
            minWidth: 260,
            valueGetter: (value, row) => {
                return row.activity
                    .map((a) => {
                        return a.name;
                    })
                    .join(", ");
            },
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 140,
            valueFormatter: (value) => `${t(value, { ns: "common" })}`,
            valueGetter: (value, row) => {
                let status = BillingStatus(row.nextPayment.status.value, row.nextPayment.dueDate, row.active);
                row.color = status.color;

                return `${status.title}`;
            },
            renderCell: (params) => {
                return (
                    <div className="flex items-center h-full">
                        <Badge color={params.row.color}>{t(params.value, { ns: "common" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "amount",
            headerName: t("Amount fee", { ns: "common" }),
            width: 120,
            sortable: false,
            valueGetter: (value, row) => {
                return `${row.nextPayment.amount || ""}`;
            },
            renderCell: (params) => {
                return <FormatPrice price={params.value} />;
            },
        },
        {
            field: "date",
            headerName: t("Next pay", { ns: "common" }),
            width: 120,
            sortable: false,
            valueGetter: (value, row) => {
                return `${row.nextPay || ""}`;
            },
            renderCell: (params) => {
                return <FormatDate date={params.value} />;
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            disableExport: true,
            width: 80,
            renderCell: (params) => {
                let options = [
                    {
                        text: t("Payments", { ns: "button" }),
                        icon: <IoWalletOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row, "payments"),
                        },
                    },
                ];

                params.row.active &&
                    params.row.kid &&
                    options.push({
                        text: t("Remove kid from activity", { ns: "button" }),
                        icon: <IoCloseOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row, "removeKid"),
                        },
                    });

                params.row.active === false &&
                    params.row.kid &&
                    options.push({
                        text: t("Restore kid to activity", { ns: "button" }),
                        icon: <IoRefreshOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row, "restoreKid"),
                        },
                    });

                return <OptionMenu options={options} />;
            },
        },
    ];

    useEffect(() => {}, []);

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
        handleLoadData();
    };

    return (
        <>
            <ContentHeading tag="h3">
                <IoWalletOutline size={"1.5rem"} />
                {t("Billings", { ns: "common" })}
            </ContentHeading>

            <DataGrid rows={billings} columns={columns} enableSearch={true} fileName={"Rozliczenia"} />

            {modal.show && modal.data && modal.type === "payments" && (
                <ModalBillingPayments billing={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "removeKid" && (
                <ModalBillingDeleteKid billing={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "restoreKid" && (
                <ModalBillingRestoreKid billing={modal.data} closeModal={handleModalClose} />
            )}
        </>
    );
}

export default BillingList;

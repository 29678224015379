import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    IoAccessibilityOutline,
    IoBusinessOutline,
    IoCalendarOutline,
    IoHappyOutline,
    IoLogOutOutline,
    IoPeopleOutline,
    IoRibbonOutline,
    IoStatsChartOutline,
    IoWalletOutline,
} from "react-icons/io5";

import AuthService from "services/AuthService";
import CurrentUser from "services/CurrentUser";
import { Logo } from "components/common/Logo";

export const Menu = () => {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const logOut = () => {
        AuthService.logout();
        window.location.reload();
    };

    return (
        <div className="bg-gray-200 w-72">
            <div className="menu w-72 py-6 px-4 fixed">
                <Logo />
                {currentUser && (
                    <ul className="menu-nav mr-auto">
                        {currentUser.roles.includes("ROLE_ADMIN") && (
                            <>
                                <li className="nav-item">
                                    <NavLink
                                        to={"/admin/statistics"}
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoStatsChartOutline size={"1.25em"} />
                                        {t("Statistics", { ns: "menu" })}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to={"/admin/users"}
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoPeopleOutline size={"1.25em"} />
                                        {t("Users", { ns: "menu" })}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to={"/admin/billings"}
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoWalletOutline size={"1.25em"} />
                                        {t("Billings", { ns: "menu" })}
                                    </NavLink>
                                </li>
                            </>
                        )}
                        <li className="nav-item">
                            <NavLink
                                to={"/calendar"}
                                className={({ isActive, isPending }) =>
                                    "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                }
                            >
                                <IoCalendarOutline size={"1.25em"} />
                                {t("Calendar", { ns: "menu" })}
                            </NavLink>
                        </li>
                        {currentUser.accountType === "brand" && (
                            <>
                                {(currentUser.roles.includes("ROLE_PROVIDER_ADMIN") ||
                                    currentUser.roles.includes("ROLE_PROVIDER_ASSISTANT")) && (
                                    <>
                                        <li className="nav-item">
                                            <NavLink
                                                to="/brand/brands"
                                                className={({ isActive, isPending }) =>
                                                    "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                                }
                                            >
                                                <IoRibbonOutline size={"1.25em"} />
                                                {t("My offer", { ns: "menu" })}
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                to="/brand/enrollments"
                                                className={({ isActive, isPending }) =>
                                                    "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                                }
                                            >
                                                <IoAccessibilityOutline size={"1.25em"} />
                                                {t("Enrollments", { ns: "menu" })}
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                to="/brand/billings"
                                                className={({ isActive, isPending }) =>
                                                    "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                                }
                                            >
                                                <IoWalletOutline size={"1.25em"} />
                                                {t("Billings", { ns: "menu" })}
                                            </NavLink>
                                        </li>
                                    </>
                                )}
                                {/* <li className="nav-item">
                                <NavLink
                                    to="/brand/materials"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoAlbumsOutline size={"1.25em"} />
                                    {t("Materials", { ns: "menu" })}
                                </NavLink>
                            </li> */}
                                {/* <li className="nav-item">
                                <NavLink
                                    to="/brand/trainings"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoSchoolOutline size={"1.25em"} />
                                    {t("Trainings", { ns: "menu" })}
                                </NavLink>
                            </li> */}
                            </>
                        )}

                        {currentUser.accountType === "institution" && (
                            <>
                                <li className="nav-item">
                                    <NavLink
                                        to="/institution/my"
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoBusinessOutline size={"1.25em"} />
                                        {t("My institution", { ns: "menu" })}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/institution/kids"
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoHappyOutline size={"1.25em"} />
                                        {t("Kids", { ns: "menu" })}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/institution/teachers"
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoPeopleOutline size={"1.25em"} />
                                        {t("Teachers", { ns: "menu" })}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/institution/activities"
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoAccessibilityOutline size={"1.25em"} />
                                        {t("Institution activities", { ns: "menu" })}
                                    </NavLink>
                                </li>
                            </>
                        )}

                        {currentUser.accountType === "parent" && (
                            <>
                                <li className="nav-item">
                                    <NavLink
                                        to="/parent/institutions"
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoBusinessOutline size={"1.25em"} />
                                        {t("Kid institution", { ns: "menu" })}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/parent/kids"
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoHappyOutline size={"1.25em"} />
                                        {t("My kid", { ns: "menu" })}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/parent/activities"
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoAccessibilityOutline size={"1.25em"} />
                                        {t("Kid activities", { ns: "menu" })}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/parent/billings"
                                        className={({ isActive, isPending }) =>
                                            "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                        }
                                    >
                                        <IoWalletOutline size={"1.25em"} />
                                        {t("Billings", { ns: "menu" })}
                                    </NavLink>
                                </li>
                            </>
                        )}

                        {/* <li className="nav-item">
                        <Link to={"/notifications"} className="nav-link">
                            <IoChatboxEllipsesOutline size={"1.25em"} />
                            {t("Notifications", { ns: "menu" })}
                        </Link>
                    </li> */}
                        {/* <li className="nav-item">
                        <Link to={"/about-app"} className="nav-link">
                            <IoInformationCircleOutline size={"1.25em"} />
                            {t("About The Kidly", { ns: "menu" })}
                        </Link>
                    </li> */}
                        <li className="nav-item">
                            <button onClick={() => logOut()} className="nav-link">
                                <IoLogOutOutline size={"1.25em"} />
                                {t("Logout", { ns: "menu" })}
                            </button>
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
};

import React from "react";

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer flex ">
            <div>
                &copy; {currentYear}, The Kidly, <span>v{process.env.REACT_APP_VERSION}</span>
            </div>
        </div>
    );
};

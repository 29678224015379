import dayjs from "dayjs";

const today = dayjs();

export function PaymentStatus(status, dueDate, required) {
    let color = "";
    let title = "";
    dueDate = dayjs(dueDate);

    if (status === "NEW") {
        if (dueDate > today) {
            color = "#0284c7";
            title = "New";
        } else {
            color = "#dc2626";
            title = "Not paid";
        }
    } else if (status === "PENDING") {
        color = "#166534";
        title = "Pending";
    } else if (status === "FAILED") {
        color = "#dc2626";
        title = "Failed";
    } else if (status === "CANCELLED" || status === "REJECTED") {
        if (required === false) {
            color = "#313333";
            title = "Not required";
        } else {
            if (dueDate > today) {
                color = "#0284c7";
                title = "Rejected";
            } else {
                color = "#dc2626";
                title = "Not paid";
            }
        }
    } else if (status === "COMPLETED") {
        color = "#166534";
        title = "Paid";
    } else if (status === "FREE") {
        color = "#313333";
        title = "Free";
    }

    return {
        color: color,
        title: title,
    };
}
